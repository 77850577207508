import(/* webpackMode: "eager" */ "/usr/src/app/frontend/global-styles/index.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ApplicationLoader"] */ "/usr/src/app/frontend/src/components/application-loader/application-loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseUrlContextProvider"] */ "/usr/src/app/frontend/src/components/common/base-url/base-url-context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FrontendConfigContextProvider"] */ "/usr/src/app/frontend/src/components/common/frontend-config-context/frontend-config-context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CachedMotdModal"] */ "/usr/src/app/frontend/src/components/global-dialogs/motd-modal/cached-motd-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DarkMode"] */ "/usr/src/app/frontend/src/components/layout/dark-mode/dark-mode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MotdProvider"] */ "/usr/src/app/frontend/src/components/motd/motd-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UiNotificationBoundary"] */ "/usr/src/app/frontend/src/components/notifications/ui-notification-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/usr/src/app/frontend/src/redux/store-provider.tsx");
